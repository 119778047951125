import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FormControl, FormGroup } from "react-bootstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import CommonService from "../services";
import { loginSubmit, setLoginData } from "../action/login/login";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import cenomiLogo from "../../src/cenomi-logo.png";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Base64 } from "js-base64";

const LoginForm = (props) => {
  const { register, errors, handleSubmit } = useForm();
  const [cookies, setCookie] = useCookies(["admin_panel_user"]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    userName: "",
    password: "",
  });
  const [validation, setValidation] = useState({
    userNameVal: "",
    passwordVal: "",
  });
  const setIsLoginClickedFunction = () => {
    props.setIsLoginClicked(true);
  };

  // const onSubmit = () => {
  //   let isValid = handleValidation();

  //   if (!isValid) {
  //     return false;
  //   }
  //   debugger;
  //   let inputData = {
  //     email: state.userName.toString(),
  //     password: state.password.toString(),
  //   };
  //   setLoading(true);
  //   dispatch(loginSubmit(inputData));
  //   try {
  //     CommonService.login(inputData)
  //       .then((response) => {
  //         if (response.data.succeeded) {
  //           setCookiesforUser(response.data.data);
  //           dispatch(setLoginData(response.data.data));
  //           setLoading(false);
  //           setIsLoginClickedFunction();
  //         } else {
  //           NotificationManager.error("Invalid Credentials.");
  //           setLoading(false);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setLoading(false);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  const onSubmit = () => {
    let isValid = handleValidation();

    if (!isValid) {
      return false;
    }
    debugger;
    let inputData = {
      Username: "Alhokair",
      Password: "4gtEh54Oy8ytB1nJa!AN",
      grant_type: "password",
    };
    setLoading(true);

    try {
      CommonService.getaccesstoken(inputData)
        .then((response) => {
          debugger;
          if (response.data.access_token) {
            handleLogin(response.data.access_token);
          } else {
            NotificationManager.error("Error");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleLogin = (token) => {
    debugger;
    let inputData = {
      email: state.userName.toString(),
      password: Base64.encode(state.password),
    };
    setLoading(true);

    try {
      debugger;
      CommonService.adLogin(inputData.email, inputData.password, token)
        .then((response) => {
          debugger;
          if (response.data) {
            setCookiesforUser(token);
            dispatch(setLoginData(token));
            setLoading(false);
            setIsLoginClickedFunction();
            console.log("Successful Login");
          } else {
            NotificationManager.error("Invalid Credentials.");
            setLoading(false);
          }
        })
        .catch((error) => {
          NotificationManager.error("Invalid Credentials.");
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      NotificationManager.error("Invalid Credentials.");
      console.log(error);
      setLoading(false);
    }
  };

  let navigate = useNavigate();
  function setCookiesforUser(data) {
    setCookie("admin_panel_user", data);
    navigate("/pricePrintingSheet");
  }

  const handleValidation = () => {
    let isValid = true;
    const validation = { userNameVal: "", passwordVal: "" };
    if (!state.userName.trim()) {
      isValid = false;
      validation.userNameVal = "UserName is Compulsory.";
    }
    if (!state.password.trim()) {
      isValid = false;
      validation.passwordVal = "Password is Compulsory.";
    }

    if (!isValid) {
      setValidation(validation);
    }

    return isValid;
  };

  const handleOnchange = (e, name) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    if (name == "userName") {
      setValidation((prevdata) => ({
        ...prevdata,
        userNameVal: "",
      }));
    }
    if (name == "password") {
      setValidation((prevdata) => ({
        ...prevdata,
        passwordVal: "",
      }));
    }
  };

  return (
    <>
      <div class="container-fluid login__section">
        <div class="row">
          <div class="col-md-8 login__block">
            <div class="row">
              <div class="col-md-6 image__block"></div>
              <div class="col-md-6">
                <div class="loginform__block">
                  {/* <div className="logo__section">
                      <img src={cenomiLogo} />
                  </div> */}
                  <h2 class="heading text-center">Login</h2>
                  <div className="form form__section">
                    {/* <form onSubmit={handleSubmit(onSubmit)} className="d-none">
                      <FormGroup>
                        <div className="input-container">
                          
                          <FormControl
                            style={{
                              borderColor:
                                errors && errors.email ? "#a80000" : "",
                            }}
                            autoComplete="off"
                            className="no-border input-no-border-radius"
                            placeholder="Email"
                            name="email"
                            type="email"
                            {...register("email", { required: true })}
                          />

                        
                        </div>
                        <div className="input-container">
                         
                          <FormControl
                            style={{
                              borderColor:
                                errors && errors.password ? "#a80000" : "",
                            }}
                            autoComplete="off"
                            className="no-border input-no-border-radius"
                            placeholder="Password"
                            name="password"
                            type="password"
                            {...register("password", { required: true })}
                          />
                          
                        </div>
                        <div className="button-container">
                          <input type="submit" className="blue__btn" />
                        </div>
                        
                      </FormGroup>
                    </form> */}

                    <form>
                      <div className="input__block">
                        <input
                          placeholder="Enter User Name"
                          type="text"
                          name="userName"
                          value={state.userName}
                          onChange={(e) => handleOnchange(e, "userName")}
                        />

                        {validation.userNameVal && (
                          <p className="error-color-red">
                            {validation.userNameVal}
                          </p>
                        )}
                      </div>

                      <div className="input__block">
                        <input
                          placeholder="Enter Password"
                          type="password"
                          name="password"
                          value={state.password}
                          onChange={(e) => handleOnchange(e, "password")}
                        />

                        {validation.passwordVal && (
                          <p className="error-color-red">
                            {validation.passwordVal}
                          </p>
                        )}
                      </div>

                      <Button
                        loading={loading}
                        onClick={onSubmit}
                        className="blue__btn w-100"
                      >
                        Login
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
};

export default LoginForm;
