import { http, https, login } from "../src/helper/http-common";
class CommonService {
  getaccesstoken(inputData) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    };
    return login.post("Price_api/api/getaccesstoken", inputData, { headers });
  }

  adLogin(username, password, token) {
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    };
    return login.get(
      "Price_api/api/AdAuth" + `?userName=${username}&password=${password}`,
      { headers }
    );
  }

  login(inputData) {
    return http.post("/api/login/post", inputData);
  }
  uploadExcel(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.post("/api/pricePrinting/UploadPricePrintExcel", inputData, {
      headers,
    });
  }
  UploadLPNPriceExcel(inputData, auth) {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + auth.admin_panel_user.token,
    };
    return http.post("/api/pricePrinting/UploadLPNPriceExcel", inputData, {
      headers,
    });
  }
  GetUploadFileDetails(inputData, auth) {
    return http.post("/api/pricePrinting/GetUploadFileDetails", inputData, {
      headers: {
        Authorization: "Bearer " + auth.admin_panel_user.token,
      },
    });
  }

  GetPricePrintingDetails(id, auth) {
    return http.get(
      "/api/pricePrinting/GetPricePrintingDetails" + `?FileId=${id}`,
      {
        headers: {
          Authorization: "Bearer " + auth.admin_panel_user.token,
        },
      }
    );
  }
  GetPrintLPNDetails(id) {
    return http.get("/api/pricePrinting/GetPrintLPNDetails" + `?FileId=${id}`);
  }

  pricePrinting(id) {
    return http.delete("/api/pricePrinting/pricePrinting/" + `${id}`);
  }
  DeleteRecords(inputData) {
    return http.post("/api/pricePrinting/DeleteRecords", inputData);
  }
  PricePrintToggle(inputData, auth) {
    return http.post("/api/pricePrinting/PricePrintToggle", inputData, {
      headers: {
        Authorization: "Bearer " + auth.admin_panel_user.token,
      },
    });
  }
  ExportPricePrintExcel(inputData, auth) {
    return http.post("/api/pricePrinting/ExportPricePrintExcel", inputData, {
      headers: {
        Authorization: "Bearer " + auth.admin_panel_user.token,
      },
    });
  }

  GetAllGroupsDetails(auth) {
    return http.get("/api/groups/GetAllGroupsDetails", {
      headers: {
        Authorization: "Bearer " + auth.admin_panel_user.token,
      },
    });
  }

  SaveRecords(inputData, auth) {
    return http.post("/api/groups/AddEditGroupsData", inputData, {
      headers: {
        Authorization: "Bearer " + auth.admin_panel_user.token,
      },
    });
  }

  UpdateRecords(inputData, auth) {
    return http.post("/api/groups/AddEditGroupsData", inputData, {
      headers: {
        Authorization: "Bearer " + auth.admin_panel_user.token,
      },
    });
  }

  GroupToggleButton(inputData, auth) {
    return http.post("/api/groups/GroupToggleAPI", inputData, {
      headers: {
        Authorization: "Bearer " + auth.admin_panel_user.token,
      },
    });
  }
}

export default new CommonService();
