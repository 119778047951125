import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, FormGroup } from "react-bootstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Table, DatePicker, Space, Spin } from "antd";
import moment from "moment";
import CommonService from "../services";
const LPNPrintingView = () => {
  const { RangePicker } = DatePicker;
  const { register, errors, handleSubmit } = useForm();
  const [cookies, setCookie] = useCookies(["admin_panel_user"]);
  const [UploadedFile, setUploadedFile] = useState();
  const [fileList, getFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    getUploadedFileDetailsList(query.get("id"));
  }, [query]);

  const navigate = useNavigate();

  const getUploadedFileDetailsList = (fileId) => {
    setLoading(true);
    try {
      CommonService.GetPrintLPNDetails(fileId)
        .then((response) => {
          if (response.data.succeeded) {
            getFileList(response.data.data);
            setLoading(false);
          } else {
            getFileList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          getFileList([]);
          setLoading(false);
        });
    } catch (error) {
      getFileList([]);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Sending location",
      dataIndex: "sendingLoc",
    },
    {
      title: "Receiving Location",
      dataIndex: "receivingLoc",
    },
    {
      title: "Transfer Number",
      dataIndex: "transferNumber",
    },
    {
      title: "Bill of Lading Number",
      dataIndex: "billOfLadingNumber",
    },
    {
      title: "Advance Shipment Notice",
      dataIndex: "advanceShipmentNotice",
    },
    {
      title: "Shipping Date",
      dataIndex: "shippingDate",

      render: (item, row) => {
        return (
          <span>{moment.utc(row.shippingDate).format("MMMM DD, YYYY")}</span>
        );
      },
    },
    {
      title: "Status of the shipment",
      dataIndex: "statusOfShipment",
      render: (item, row) => {
        return <span>{row.statusOfShipment == true ? "Yes" : "No"}</span>;
      },
    },
    {
      title: "License Plate Number",
      dataIndex: "licensePlateNumber",
    },
    {
      title: "Quantity",
      dataIndex: "quantityInBox",
    },
    {
      title: "Order of the box",
      dataIndex: "boxesOrderinTotalBox",
    },
    {
      title: "Total number of boxes",
      dataIndex: "totalBoxes",
    },
  ];

  const handleBackButtonClick = () => {
    navigate("/manageGroup");
  };

  return (
    <div className="">
      <div>
        <div className="d-flex">
          <i
            onClick={() => handleBackButtonClick()}
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
          <h3 className="page__heading ml-3">LPN Printing View Sheet</h3>
        </div>
        <div>
          <div>
            <Spin spinning={loading}>
              <Table
                className="pendjewelreq__tbl"
                columns={columns}
                dataSource={fileList}
                scroll={{ x: 600, y: 340 }}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LPNPrintingView;
