import { LOGIN_SUBMIT, LOGIN_SUBMIT_FIRST } from "./types";
import CommonService from "../../services";

export const setLoginData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_SUBMIT,
      payload: data,
    });
  } catch (err) {}
};

export const loginSubmit = (inputDate) => async (dispatch) => {
  try {
    debugger;
    const res = await CommonService.login(inputDate);
    dispatch({
      type: LOGIN_SUBMIT_FIRST,
      payload: res.data.data,
    });
    return Promise.resolve(res.data.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
