import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, FormGroup } from "react-bootstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Table, DatePicker, Space, Spin, Button, Modal, Switch } from "antd";
import moment from "moment";
import * as XLSX from "xlsx";
import CommonService from "../services";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import config from "../helper/config";
import { useAuth } from "./../ProvideAuth";
var FileSaver = require("file-saver");

export const DownloadFile = (jsonData, filename) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const ws = XLSX.utils.json_to_sheet(jsonData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, filename);
};

const PricePrintingSheet = () => {
  const { RangePicker } = DatePicker;
  const { register, errors, handleSubmit } = useForm();
  const [cookies, setCookie] = useCookies(["admin_panel_user"]);
  const [UploadedFile, setUploadedFile] = useState(null);
  const [fileList, getFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMouseLeave, setIsMouseLeave] = useState(false);
  let auth = useAuth();

  useEffect(() => {
    getUploadedFilesList();
  }, []);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [state, setState] = useState({
    startDate: "",
    endDate: "",
    fileName: "",
  });
  const [isShow, setIsShow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [key, setKey] = useState(false);

  const onSubmit = () => {
    debugger;
    console.log(UploadedFile);
    if (UploadedFile) {
      if (UploadedFile.name.match(/.(xls|xlsx)$/i)) {
      } else {
        NotificationManager.error("Please Select Correct File.");
        setUploadedFile(null);
        document.getElementById("inputGroupFile01").value = "";
        return false;
      }
      console.log(UploadedFile.name);
    } else {
      NotificationManager.error("Please Select the file.");
      return false;
    }
    const formData = new FormData();
    formData.append("UploadedFile", UploadedFile);
    setUploadLoading(true);

    try {
      CommonService.uploadExcel(formData)
        .then((response) => {
          debugger;

          if (response.data.message == "File Uploaded Successfully") {
            setUploadLoading(false);
            NotificationManager.success("File Uploaded Successfully.");
            getUploadedFilesList();
            setUploadedFile(null);
            document.getElementById("inputGroupFile01").value = "";
          } else if (
            response.data.message ==
            "Please upload correct data!! Number of prints, Label size, Type and Print Barcode is required."
          ) {
            setUploadLoading(false);
            NotificationManager.error("Please Upload Correct Data.");
            getUploadedFilesList();
            setUploadedFile(null);
            document.getElementById("inputGroupFile01").value = "";
          } else if (
            response.data.message == "Please upload correct excel format!!"
          ) {
            setUploadLoading(false);
            NotificationManager.error("Please Upload Correct Excel Format.");
            getUploadedFilesList();
            setUploadedFile(null);
            document.getElementById("inputGroupFile01").value = "";
          } else {
            setUploadLoading(false);
            NotificationManager.error("File Not Uploaded");
            getUploadedFilesList();
            setUploadedFile(null);
            document.getElementById("inputGroupFile01").value = "";
          }
        })
        .catch((error) => {
          setUploadLoading(false);
          NotificationManager.error("Error");
          setUploadedFile(null);
          document.getElementById("inputGroupFile01").value = "";
          console.log(error);
        });
    } catch (error) {
      setUploadLoading(false);
      NotificationManager.error("Error");
      setUploadedFile(null);
      document.getElementById("inputGroupFile01").value = "";
      console.log(error);
    }
  };

  const onDateChange = (dates, dateStrings) => {
    debugger;
    if (dates) {
      setState((prevState) => ({
        ...prevState,
        startDate: moment.utc(dates[0].$d).toISOString(),
        endDate: moment.utc(dates[1].$d).toISOString(),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        startDate: "",
        endDate: "",
      }));
      console.log("Clear");
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setState({
        ...state,
        [name]: e,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleOnchangeToggle = (e, fileId) => {
    debugger;
    let tempaArray = fileList
      .filter((x) => x.fileId == fileId)
      .map((x) => (x.isActive = e));
    handleOnToggleSwitch(fileId, e);
  };

  const getUploadedFilesList = (startDate, endDate, filename) => {
    debugger;
    let inputData = {
      fileType: "price",
      startDate:
        startDate == "1"
          ? ""
          : state.startDate
          ? moment(state.startDate).format("YYYY-MM-DD")
          : "",
      endDate:
        endDate == "1"
          ? ""
          : state.endDate
          ? moment(state.endDate).format("YYYY-MM-DD")
          : "",
      fileName: filename == "1" ? "" : state.fileName.toString(),
    };
    setLoading(true);
    try {
      CommonService.GetUploadFileDetails(inputData, auth)
        .then((response) => {
          if (response.data.succeeded) {
            setLoading(false);
            getFileList(response.data.data);
            // setState((prevdata) => ({
            //   ...prevdata,
            //   startDate: "",
            //   endDate: "",
            //   fileName: "",
            // }));
          } else {
            setLoading(false);
            getFileList([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          getFileList([]);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      getFileList([]);
      console.log(error);
    }
  };

  const handleOnDelete = (id) => {
    setDeleteId(id);
    setIsModalVisible(true);
  };

  const handleOnDeleteClick = () => {
    let inputData = {
      fileId: deleteId ? deleteId : 0,
      // fileType: "Price",
    };
    setDeleteLoading(true);
    try {
      CommonService.DeleteRecords(inputData)
        .then((response) => {
          if (response.data.succeeded) {
            if (response.data.message == "Record deleted successfully!!") {
              setDeleteLoading(false);
              setIsModalVisible(false);

              NotificationManager.success("Record Deleted Successfully.");
              getUploadedFilesList();
            } else {
              setDeleteLoading(false);
              setIsModalVisible(false);
              NotificationManager.error("Record Not Deleted Successfully.");
              getUploadedFilesList();
            }
          } else {
            setDeleteLoading(false);
            setIsModalVisible(false);
            NotificationManager.error("Record Not Deleted Successfully.");
            getUploadedFilesList();
          }
        })
        .catch((error) => {
          setDeleteLoading(false);
          setIsModalVisible(false);
          NotificationManager.error("Error");
        });
    } catch (error) {
      setDeleteLoading(false);
      setIsModalVisible(false);
      NotificationManager.error("Error");
    }
  };

  const handleOnToggleSwitch = (id, isActive) => {
    let inputData = {
      fileId: id,
      isActive: isActive,
      // fileType: "price",
    };
    try {
      CommonService.PricePrintToggle(inputData, auth)
        .then((response) => {
          debugger;
          if (response.data.succeeded) {
            debugger;
            if (response.data.message == "Record updated successfully!!") {
              NotificationManager.success("Record Updated Successfully.");
              getUploadedFilesList();
            } else {
              NotificationManager.error("Record Not Updated Successfully.");
              getUploadedFilesList();
            }
          } else {
            NotificationManager.error("Record Not Updated Successfully.");
            getUploadedFilesList();
          }
        })
        .catch((error) => {
          NotificationManager.error("Error");
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error("Error");
      console.log(error);
    }
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      width: "30%",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (item, row) => {
        return (
          <span>{moment.utc(row.createdDate).format("MMMM DD, YYYY")}</span>
        );
      },
    },

    {
      title: "Active",

      render: (item, row) => {
        return (
          <Switch
            name="enabled"
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={row.isActive}
            // onChange={(e) => handleOnToggleSwitch(item.fileId, e)}
            onChange={(e) => handleOnchangeToggle(e, row.fileId)}
          />
        );
      },
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Link
            to={`/pricePrintingView?id=${record.fileId}`}
            className="view-btn-table cursor"
          >
            <a className="linkText">
              <i className="fa fa-eye" aria-hidden="true"></i>
            </a>
          </Link>
          <a
            className="linkText"
            // onClick={() => handleOnDeleteClick(record.fileId)}
            onClick={() => handleOnDelete(record.fileId)}
          >
            <i className="fa fa-trash-o" aria-hidden="true"></i>
          </a>

          <a className="linkText" onClick={() => handleDownload(record.fileId)}>
            <i class="fa fa-download" aria-hidden="true"></i>
          </a>
        </Space>
      ),
    },
  ];

  const handleDownload = (id) => {
    let inputData = {
      fileId: id,
      // fileType: "Price",
    };

    try {
      CommonService.ExportPricePrintExcel(inputData, auth)
        .then((response) => {
          if (response.data.isSuccess) {
            window.open(config.BaseURL + response.data.fileUrl, "__blank");
          } else {
            NotificationManager.error("File Not Download");
          }
        })
        .catch((error) => {
          console.log(error);
          NotificationManager.error("Error");
        });
    } catch (error) {
      console.log(error);
      NotificationManager.error("Error");
    }
  };

  const downloadTemplateData = [
    {
      Barcode: "175926708016",
      Sku: "305895061",
      "Item Description": "V-HR GIRLFRIEND KHAKI",
      "Old Price": "229",
      "Current Price": "249",
      "Product Family": "LONG PAN",
    },
  ];

  const handleOnShow = () => {
    setIsShow(!isShow);
  };

  // const handleMouseEnter = () => {
  //   if (isShow == false) {
  //     setIsMouseLeave(false);
  //     alert("Hello");
  //   }
  // };

  // const handleMouseLeave = () => {
  //   setIsMouseLeave(true);
  // };

  const handleResetFilter = () => {
    setKey(!key);
    setState({
      startDate: "",
      endDate: "",
      fileName: "",
    });
    getUploadedFilesList("1", "1", "1");
  };

  return (
    <>
      <div>
        <h3 className="page__heading">Price Printing Sheet</h3>
      </div>

      <div className="filter__section">
        <div className="row">
          <div className="col-md-4">
            <div className="upload__excel input__block">
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Form.Control
                    type="file"
                    id="inputGroupFile01"
                    onChange={(e) => setUploadedFile(e.target.files[0])}
                  />
                  <Button
                    loading={uploadLoading}
                    htmlType="submit"
                    className="blue__btn mr-1"
                  >
                    <i class="fa fa-upload" aria-hidden="true"></i>
                  </Button>

                  <Button onClick={() => handleOnShow()} className="blue__btn">
                    <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                  </Button>
                </FormGroup>
              </form>
            </div>
          </div>

          <div className="col-md-3">
            <div className="input__block">
              <RangePicker
                key={key}
                className="date-range-input"
                // ranges={{
                //   Today: [moment(), moment()],
                //   Yesterday: [
                //     moment().subtract(1, "days"),
                //     moment().subtract(1, "days"),
                //   ],
                //   "Last 7 Days": [moment().subtract(7, "days"), moment()],
                //   "Last 30 Days": [moment().subtract(30, "days"), moment()],
                //   "This Month": [
                //     moment().startOf("month"),
                //     moment().endOf("month"),
                //   ],
                //   "Last Month": [
                //     moment().subtract(1, "month").startOf("month"),
                //     moment().subtract(1, "months").endOf("month"),
                //   ],
                //   "Last 90 Days": [moment().subtract(90, "days"), moment()],
                //   "Year To Date": [moment().startOf("year"), moment()],
                // }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                // defaultValue={[moment().subtract(30, "days"), moment()]}
                // defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form__section">
              <div className="input__block">
                <input
                  type="text"
                  placeholder="Enter File Name"
                  name="fileName"
                  value={state.fileName}
                  onChange={(e) => handleOnChange(e, "", "fileName")}
                />
              </div>
            </div>
          </div>

          <div className="d-flex col-md-3">
            <Button
              onClick={() => getUploadedFilesList()}
              type="primary"
              htmlType="submit"
              className="blue__btn w-100"
            >
              Apply Filter
            </Button>
            <Button
              onClick={() => handleResetFilter()}
              type="primary"
              htmlType="submit"
              className="blue__btn w-100 ml-1"
            >
              Reset Filter
            </Button>
          </div>

          {isShow && (
            <div className="col-md-12">
              <div className="upload__format">
                <div className="d-flex">
                  <div>
                    <h4 className="page__heading mb-2">Excel Format</h4>
                  </div>
                  <div className="ml-3">
                    <a
                      onClick={() => {
                        DownloadFile(
                          downloadTemplateData,
                          "Price_Template.xlsx"
                        );
                      }}
                      download
                      className="linkText"
                    >
                      Download Template
                    </a>
                  </div>
                </div>

                <div>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Barcode</th>
                        <th scope="col">Sku</th>
                        <th scope="col">Item Description</th>
                        <th scope="col">Old Price</th>
                        <th scope="col">Current Price</th>
                        <th scope="col">Product Family</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>175926708016</td>
                        <td>305895061</td>
                        <td>V-HR GIRLFRIEND KHAKI</td>
                        <td>229</td>
                        <td>249</td>
                        <td>LONG PAN</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="table__block">
        <Spin spinning={loading}>
          <Table
            className="pendjewelreq__tbl"
            columns={columns}
            dataSource={fileList}
            scroll={{ x: 600, y: 340 }}
          />
        </Spin>
      </div>

      <Modal
        title=""
        centered
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width={400}
        footer={[
          <Button
            loading={deleteLoading}
            key="submit"
            type="primary"
            onClick={() => handleOnDeleteClick()}
          >
            Ok
          </Button>,
        ]}
      >
        <div className="text-center">
          <h5> Are You Sure Want to delete ?</h5>
        </div>
      </Modal>
    </>
  );
};

export default PricePrintingSheet;
