import { LOGIN_SUBMIT, LOGIN_SUBMIT_FIRST } from "../../action/login/types";
const initialState = {
  loginDetials: null,
};

function loginReducer(loginState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUBMIT:
      return { ...loginState, loginDetials: payload };
    case LOGIN_SUBMIT_FIRST:
      return { ...loginState, loginDetials: payload };
    default:
      return loginState;
  }
}
export default loginReducer;
