import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import "./style.css";
import LoginForm from "./Login/loginForm";
import PricePrintingSheet from "./PrintingSheets/PricePrintingSheet";
import GlobalNavBar from "./Login/GlobalNavBar";
import LPNPrintingSheet from "./PrintingSheets/LPNPrintingSheet";
import UploadedDataView from "./PrintingSheets/PricePrintingView";
import PricePrintingView from "./PrintingSheets/PricePrintingView";
import { ProvideAuth, useAuth } from "./ProvideAuth";
import { NotificationContainer } from "react-notifications";

import logoImg from "../src/cenomi-logo.png";
import LPNPrintingView from "./PrintingSheets/LPNPrintingView";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function App() {
  let auth = useAuth();
  const loginDetails = useSelector((state) => state.loginReducer);
  const [isLogin, setIsLogin] = useState(false);
  const [isLoginClicked, setIsLoginClicked] = useState(false);

  const [loginDetailsState, setLoginDetailsState] = useState({});
  useEffect(() => {
    setLoginDetailsState(loginDetails);
  }, [isLoginClicked]);

  // console.log(loginDetailsState?.loginDetials?.token);

  return (
    <ProvideAuth>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<LoginForm setIsLoginClicked={setIsLoginClicked} />}
          />
        </Routes>
        {loginDetailsState?.loginDetials && (
          <div className="main__section">
            <div className="sidebar">
              <div className="dashboard__logo">
                <img src={logoImg} />
              </div>
              <div className="sidebar__navigation">
                <GlobalNavBar setIsLoginClicked={setIsLoginClicked} />
              </div>
            </div>

            <div className="right__section">
              <div className="header__section">
                {/* <GlobalNavBar setIsLoginClicked={setIsLoginClicked} /> */}
                <div className="user__avtar">
                  <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                </div>
              </div>
              <div className="contain__area">
                <Routes>
                  <Route
                    path="/pricePrintingSheet"
                    element={<PricePrintingSheet />}
                  />
                  <Route path="/manageGroup" element={<LPNPrintingSheet />} />
                  <Route
                    path="/pricePrintingView"
                    element={<PricePrintingView />}
                  />
                  <Route
                    path="/lpnPrintingView"
                    element={<LPNPrintingView />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        )}

        <NotificationContainer />
      </BrowserRouter>
    </ProvideAuth>
  );
}

export default App;
