import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useAuth } from "./../ProvideAuth";
import { setLoginData } from "../action/login/login";
import { useDispatch } from "react-redux";

const GlobalNavBar = (props) => {
  const [cookies, remove] = useCookies(["real_estate_user"]);
  const dispatch = useDispatch();
  const handleSetLoginData = () => {
    props.setIsLoginClicked(false);
  };
  let auth = useAuth();
  let navigate = useNavigate();
  console.log(auth?.admin_panel_user);
  return (
    auth &&
    auth.admin_panel_user && (
      // auth.admin_panel_user.token && (
      <Navbar
      // collapseOnSelect
      // expand="sm"
      // bg="light"
      // variant="light"
      // className="mb-3"
      >
        <Nav className="w-100">
          <div className="navbar__div">
            <div className="nav__links">
              <Nav.Link
                // className="nav-link"
                className={
                  window.location.href.indexOf("pricePrintingSheet") > -1 ||
                  window.location.href.indexOf("pricePrintingView") > -1
                    ? "nav-link active"
                    : "nav-link"
                }
                as={Link}
                to="/pricePrintingSheet"
              >
                Price Printing
              </Nav.Link>
              {/* <Nav.Link
                className={
                  window.location.href.indexOf("manageGroup") > -1 ||
                  window.location.href.indexOf("lpnPrintingView") > -1
                    ? "nav-link active"
                    : "nav-link"
                }
                as={Link}
                to="/manageGroup"
              >
                Add / Manage Groups
              </Nav.Link> */}
            </div>
            <div className="logout__btn">
              <Nav.Link>
                <Button
                  // className="blue__btn"
                  onClick={() => {
                    remove("admin_panel_user", { path: "/" });
                    navigate("/");
                    dispatch(setLoginData(null));
                    handleSetLoginData();
                  }}
                >
                  Log Out
                </Button>
              </Nav.Link>
            </div>
          </div>
        </Nav>
      </Navbar>
    )
  );
};

export default GlobalNavBar;
