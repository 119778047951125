import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, FormGroup } from "react-bootstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Table, DatePicker, Space, Button, Spin, Switch, Modal } from "antd";
import moment from "moment";
import CommonService from "../services";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import * as XLSX from "xlsx";
import config from "../helper/config";
import { useAuth } from "./../ProvideAuth";
var FileSaver = require("file-saver");
export const DownloadFile = (jsonData, filename) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const ws = XLSX.utils.json_to_sheet(jsonData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, filename);
};

const LPNPrintingSheet = () => {
  const { RangePicker } = DatePicker;
  const { register, errors, handleSubmit } = useForm();
  const [cookies, setCookie] = useCookies(["admin_panel_user"]);
  const [UploadedFile, setUploadedFile] = useState();
  const [fileList, getFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [state, setState] = useState({
    startDate: "",
    endDate: "",
    fileName: "",
  });
  const [isShow, setIsShow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  //const [editGroupName, setEditGroupName] = useState(0);
  const [groupId, setGroupId] = useState(0);
  let auth = useAuth();

  useEffect(() => {
    getAllGroupDetailsList();
  }, []);

  const getAllGroupDetailsList = () => {
    // let inputData = {
    //   fileType: "LPN",
    //   startDate:
    //     startDate == "1"
    //       ? ""
    //       : state.startDate
    //       ? moment(state.startDate).format("YYYY-MM-DD")
    //       : "",
    //   endDate:
    //     endDate == "1"
    //       ? ""
    //       : state.endDate
    //       ? moment(state.endDate).format("YYYY-MM-DD")
    //       : "",
    //   fileName: fileName == "1" ? "" : state.fileName.toString(),
    // };
    setLoading(true);
    try {
      CommonService.GetAllGroupsDetails(auth)
        .then((response) => {
          if (response.data.succeeded) {
            setLoading(false);
            getFileList(response.data.data);
            // setState((prevdata) => ({
            //   ...prevdata,
            //   startDate: "",
            //   endDate: "",
            //   fileName: "",
            // }));
          } else {
            setLoading(false);
            getFileList([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          getFileList([]);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      getFileList([]);
      console.log(error);
    }
  };

  // const handleOnDeleteClick = () => {
  //   let inputData = {
  //     fileId: deleteId ? deleteId : 0,
  //     fileType: "LPN",
  //   };
  //   setDeleteLoading(true);
  //   try {
  //     CommonService.DeleteRecords(inputData)
  //       .then((response) => {
  //         if (response.data.isSuccess) {
  //           if (response.data.messsage == "Record  Deleted Successfully") {
  //             setDeleteLoading(false);
  //             setIsModalVisible(false);

  //             NotificationManager.success("Record Deleted Successfully.");
  //             getAllGroupDetailsList();
  //           } else {
  //             setDeleteLoading(false);
  //             setIsModalVisible(false);
  //             NotificationManager.error("Record Not Deleted Successfully.");
  //             getAllGroupDetailsList();
  //           }
  //         } else {
  //           setDeleteLoading(false);
  //           setIsModalVisible(false);
  //           NotificationManager.error("Record Not Deleted Successfully.");
  //           getAllGroupDetailsList();
  //         }
  //       })
  //       .catch((error) => {
  //         setDeleteLoading(false);
  //         setIsModalVisible(false);
  //         NotificationManager.error("Error");
  //       });
  //   } catch (error) {
  //     setDeleteLoading(false);
  //     setIsModalVisible(false);
  //     NotificationManager.error("Error");
  //     console.log(error);
  //   }
  // };

  const handleOnSaveClick = () => {
    let inputData = {
      groupName,
    };
    //setDeleteLoading(true);
    try {
      CommonService.SaveRecords(inputData, auth)
        .then((response) => {
          if (response.data.succeeded) {
            if (response.data.message) {
              //setDeleteLoading(false);
              setIsAddModalVisible(false);

              NotificationManager.success(response.data.message);
              getAllGroupDetailsList();
            }
          } else {
            //setDeleteLoading(false);
            setIsAddModalVisible(false);
            NotificationManager.error(response.data.message);
            getAllGroupDetailsList();
          }
        })
        .catch((error) => {
          //setDeleteLoading(false);
          setIsAddModalVisible(false);
          NotificationManager.error("Error");
        });
    } catch (error) {
      //setDeleteLoading(false);
      setIsAddModalVisible(false);
      NotificationManager.error("Error");
      console.log(error);
    }
  };

  const handleOnUpdateClick = () => {
    let inputData = {
      id: groupId,
      groupName,
    };
    //setDeleteLoading(true);
    try {
      CommonService.UpdateRecords(inputData, auth)
        .then((response) => {
          console.log(response);
          if (response.data.succeeded) {
            if (response.data.message) {
              //setDeleteLoading(false);
              setIsEditModalVisible(false);

              NotificationManager.success(response.data.message);
              getAllGroupDetailsList();
            }
          } else {
            //setDeleteLoading(false);
            setIsEditModalVisible(false);
            NotificationManager.error(response.data.message);
            getAllGroupDetailsList();
          }
        })
        .catch((error) => {
          //setDeleteLoading(false);
          setIsEditModalVisible(false);
          NotificationManager.error("Error");
        });
    } catch (error) {
      //setDeleteLoading(false);
      setIsEditModalVisible(false);
      NotificationManager.error("Error");
      console.log(error);
    }
  }

  const columns = [
    {
      title: "Group Name",
      dataIndex: "groupName",
      width: "30%",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (item, row) => {
        return (
          <span>{moment.utc(row.createdDate).format("MMMM DD, YYYY")}</span>
        );
      },
    },
    {
      title: "Active",

      render: (item, row) => {
        return (
          <Switch
            name="enabled"
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={row.isActive}
            // onChange={(e) => handleOnToggleSwitch(item.fileId, e)}
            onChange={(e) => handleOnchangeToggle(e, row.id)}
          />
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <Link
            to={`/lpnPrintingView?id=${record.fileId}`}
            className="view-btn-table cursor"
          >
            <a className="linkText">
              <i className="fa fa-edit" aria-hidden="true"></i>
            </a>
          </Link> */}
          <a className="linkText" onClick={() => handleOnEdit(record)}>
            <i className="fa fa-edit" aria-hidden="true"></i>
          </a>
          {/* <a className="linkText" onClick={() => handleDownload(record.fileId)}>
            <i class="fa fa-download" aria-hidden="true"></i>
          </a> */}
        </Space>
      ),
    },
  ];

  const handleDownload = (id) => {
    let inputData = {
      fileId: id,
      fileType: "LPN",
    };

    try {
      CommonService.DownloadRecordsToExcel(inputData)
        .then((response) => {
          if (response.data.isSuccess) {
            window.open(config.BaseURL + response.data.fileUrl, "__blank");
          } else {
            NotificationManager.error("File Not Download");
          }
        })
        .catch((error) => {
          console.log(error);
          NotificationManager.error("Error");
        });
    } catch (error) {
      console.log(error);
      NotificationManager.error("Error");
    }
  };

  const handleOnDelete = (id) => {
    setDeleteId(id);
    setIsModalVisible(true);
  };

  const handleOnEdit = (data) => {
    setGroupId(data.id);
    setGroupName(data.groupName);
    setIsEditModalVisible(true);
  };

  const handleOnchangeToggle = (e, fileId) => {
    debugger;
    let tempaArray = fileList
      .filter((x) => x.fileId == fileId)
      .map((x) => (x.isActive = e));
      console.log(e);
      console.log(fileId);
    handleOnToggleSwitch(fileId, e);
  };

  const handleOnToggleSwitch = (id, isActive) => {
    let inputData = {
      id,
      isActive,
    };
    try {
      CommonService.GroupToggleButton(inputData, auth)
        .then((response) => {
          if (response.data.succeeded) {
            if (response.data.message) {
              NotificationManager.success(response.data.message);
              getAllGroupDetailsList();
            }
          } else {
            NotificationManager.error(response.data.message);
            getAllGroupDetailsList();
          }
        })
        .catch((error) => {
          console.log(error);
          NotificationManager.error("Error");
        });
    } catch (error) {
      console.log(error);
      NotificationManager.error("Error");
    }
  };

  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setState((prevState) => ({
        ...prevState,
        startDate: moment.utc(dates[0].$d).toISOString(),
        endDate: moment.utc(dates[1].$d).toISOString(),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        startDate: "",
        endDate: "",
      }));
      console.log("Clear");
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setState({
        ...state,
        [name]: e,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleOnShow = () => {
    setIsShow(!isShow);
  };

  const downloadTemplateData = [
    {
      "ASN Number": 8383,
      "Sending Location": "Test1",
      "Receiving Location": "Test2",
      "Transfer Number": "9239",
      "Bill Of Landing Number": "29383",
      "Advance Shipment Notice": "Test notice",
      "Shipping Date": "5/9/2023",
      "Status Of Shipment": "Yes",
      "License Plate Number": "83283",
      "Quantity In Box": "1",
      "Total Box": "2",
      "Order of the box in the total boxes": "1",
    },
  ];

  const handleResetFilter = () => {
    // setState({
    //   startDate: "",
    //   endDate: "",
    //   fileName: "",
    // });
    getAllGroupDetailsList("1", "1", "1");
  };

  return (
    <>
      <div>
        <h3 className="page__heading">Add / Manage Groups</h3>
      </div>

      <div className="filter__section">
        <div className="row">
          <div className="col-md-4">
            {/* <div className="upload__excel input__block">
              <div className="d-flex col-md-3">
                <Button
                  onClick={() => getUploadedFilesList()}
                  type="primary"
                  htmlType="submit"
                  className="blue__btn w-100"
                >
                  Add
                </Button>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Form.Control
                    type="file"
                    id="inputGroupFile01"
                    onChange={(e) => setUploadedFile(e.target.files[0])}
                  />
                  <Button
                    loading={uploadLoading}
                    htmlType="submit"
                    className="blue__btn mr-1"
                  >
                    <i class="fa fa-upload" aria-hidden="true"></i>
                  </Button>

                  <Button onClick={() => handleOnShow()} className="blue__btn">
                    <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                  </Button>
                </FormGroup>
              </form>
            </div> */}
          </div>

          {/* <div className="col-md-3">
            <div className="input__block">
              <RangePicker
                className="date-range-input"
                // ranges={{
                //   Today: [moment(), moment()],
                //   Yesterday: [
                //     moment().subtract(1, "days"),
                //     moment().subtract(1, "days"),
                //   ],
                //   "Last 7 Days": [moment().subtract(7, "days"), moment()],
                //   "Last 30 Days": [moment().subtract(30, "days"), moment()],
                //   "This Month": [
                //     moment().startOf("month"),
                //     moment().endOf("month"),
                //   ],
                //   "Last Month": [
                //     moment().subtract(1, "month").startOf("month"),
                //     moment().subtract(1, "months").endOf("month"),
                //   ],
                //   "Last 90 Days": [moment().subtract(90, "days"), moment()],
                //   "Year To Date": [moment().startOf("year"), moment()],
                // }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                // defaultValue={[moment().subtract(30, "days"), moment()]}
                // defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          <div className="col-md-2">
            <div className="form__section">
              <div className="input__block">
                <input
                  type="text"
                  placeholder="Enter File Name"
                  name="fileName"
                  value={state.fileName}
                  onChange={(e) => handleOnChange(e, "", "fileName")}
                />
              </div>
            </div>
          </div> */}

          <div className="margin-btn col-md-1">
            <Button
              onClick={() => setIsAddModalVisible(true)}
              type="primary"
              htmlType="submit"
              className="blue__btn w-100"
            >
              Add
            </Button>
            {/* <Button
              onClick={() => handleResetFilter()}
              type="primary"
              htmlType="submit"
              className="blue__btn w-100 ml-1"
            >
              Reset Filter
            </Button> */}
          </div>
          {isShow && (
            <div className="col-md-12">
              <div className="upload__format">
                <div className="d-flex">
                  <div>
                    <h4 className="page__heading mb-2">Excel Format</h4>
                  </div>
                  <div className="ml-3">
                    <a
                      onClick={() => {
                        DownloadFile(downloadTemplateData, "LPN_Template.xlsx");
                      }}
                      download
                      className="linkText"
                    >
                      Download Template
                    </a>
                  </div>
                </div>
                <div>
                  <table class="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">ASN Number</th>
                        <th scope="col">Sending Location</th>
                        <th scope="col">Receiving Location</th>
                        <th scope="col">Transfer Number</th>
                        <th scope="col">Bill Of Landing Number</th>
                        <th scope="col">Advance Shipment Notice</th>
                        <th scope="col">Shipping Date</th>
                        <th scope="col">Status Of Shipment</th>
                        <th scope="col">License Plate Number</th>
                        <th scope="col">Quantity In Box</th>
                        <th scope="col">Total Box</th>
                        <th scope="col">Order of the box in the total boxes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>8383</td>
                        <td>Test1</td>
                        <td>Test2</td>
                        <td>9239</td>
                        <td>29383</td>
                        <td>Test notice</td>
                        <td>5/9/2023</td>
                        <td>Yes</td>
                        <td>83283</td>
                        <td>1</td>
                        <td>2</td>
                        <td>1</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="table__block">
        <Spin spinning={loading}>
          <Table
            className="pendjewelreq__tbl"
            columns={columns}
            dataSource={fileList}
            scroll={{ x: 600, y: 340 }}
          />
        </Spin>
      </div>
      <Modal
        title=""
        centered
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width={400}
        footer={[
          <Button
            loading={deleteLoading}
            key="submit"
            type="primary"
            onClick={() => handleOnUpdateClick()}
          >
            Ok
          </Button>,
        ]}
      >
        <div className="text-center">
          <h5> Are You Sure Want to delete ?</h5>
        </div>
      </Modal>
      <Modal
        title=""
        centered
        visible={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        width={400}
        footer={[
          <Button
            loading={deleteLoading}
            key="submit"
            type="primary"
            onClick={() => handleOnSaveClick()}
          >
            Save
          </Button>,
        ]}
      >
        <div className="text-center">
          <h5>Add Group</h5>
          <div className="d-modal">
            <label>Group Name</label>
            <input type="text" onChange={(e) => setGroupName(e.target.value)} />
          </div>
        </div>
      </Modal>
      <Modal
        title=""
        centered
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        width={400}
        footer={[
          <Button
            loading={deleteLoading}
            key="submit"
            type="primary"
            onClick={() => handleOnUpdateClick()}
          >
            Update
          </Button>,
        ]}
      >
        <div className="text-center">
          <h5>Edit Group</h5>
          <div className="d-modal">
            <label>Group Name</label>
            <input type="text" value= {groupName} onChange={(e) => setGroupName(e.target.value)} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LPNPrintingSheet;
