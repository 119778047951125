import axios from "axios";
import config from "./config";
import AuthHeader from "./auth";

console.log(AuthHeader());
export const http = axios.create({
  baseURL: config.BaseURL,
  headers: {
    "Content-Type": "application/json,application/octet-stream",
    "Access-Control-Allow-Origin": "*",
  },
});

export const https = axios.create({
  baseURL: config.BaseURL,
  headers: {
    "Content-Type": "application/json,multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});

export const login = axios.create({
  baseURL: config.LoginURL,
});
