import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, FormGroup } from "react-bootstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Table, DatePicker, Space, Spin } from "antd";
import moment from "moment";
import CommonService from "../services";
import { useAuth } from "./../ProvideAuth";
const PricePrintingView = () => {
  const { RangePicker } = DatePicker;
  const { register, errors, handleSubmit } = useForm();
  const [cookies, setCookie] = useCookies(["admin_panel_user"]);
  const [UploadedFile, setUploadedFile] = useState();
  const [fileList, getFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  let auth = useAuth();
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    getUploadedFileDetailsList(query.get("id"));
  }, [query]);

  const navigate = useNavigate();

  const getUploadedFileDetailsList = (fileId) => {
    setLoading(true);
    try {
      CommonService.GetPricePrintingDetails(fileId, auth)
        .then((response) => {
          if (response.data.succeeded) {
            if (response.data.message == "Files get successfully") {
              getFileList(response.data.data);
              setLoading(false);
            } else {
              getFileList([]);
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          getFileList([]);
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      getFileList([]);
      setLoading(false);
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Barcode",
      dataIndex: "barcode",
    },
    {
      title: "SKU",
      dataIndex: "sku",
    },

    {
      title: "Item Description",
      dataIndex: "item_Desc",
      width: "30%",
    },
    {
      title: "Old Price",
      dataIndex: "old_Price",
    },
    {
      title: "Current Price",
      dataIndex: "current_Price",
    },
    {
      title: "Product Family",
      dataIndex: "product_Family",
    },
  ];

  const handleBackButtonClick = () => {
    navigate("/pricePrintingSheet");
  };

  return (
    <div className="">
      <div>
        <div className="d-flex">
          <i
            onClick={() => handleBackButtonClick()}
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
          <h3 className="page__heading ml-3">Price Printing View Sheet</h3>
        </div>
        <div>
          <div>
            <Spin spinning={loading}>
              <Table
                className="pendjewelreq__tbl"
                columns={columns}
                dataSource={fileList}
                scroll={{ x: 600, y: 340 }}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricePrintingView;
